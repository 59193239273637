import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { SearchState } from '@/utils/searchState';
import { TCategory } from '@/utils/categories';
import { Seo } from '@/components/Seo';
import { SEO_DATA } from '@/utils/seoConstants/home-page';
import ssr from '@/utils/ssr';

const App = dynamic(() => import(`@/components/AppWrapper/AppWrapper`));

export interface Props {
  resultsState: any;
  searchStateProps: any;
  currentCategoryProps?: TCategory | undefined;
}

const Home = ({ resultsState, searchStateProps }: Props) => {
  const [searchState, setSearchState] = useState<SearchState>(searchStateProps);

  const onSearchStateChange = (searchState: SearchState) => {
    setSearchState((state) => {
      return {
        ...state,
        ...searchState,
      };
    });
  };

  const resetPage = () => {
    setSearchState((state) => {
      return {
        ...state,
        page: 1,
      };
    });
  };

  return (
    <>
      <App
        resetPage={resetPage}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        resultsState={resultsState}
      />
      <Seo {...SEO_DATA} />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export async function getServerSideProps(context) {
  return await ssr(context);
}

export default Home;
