import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';

interface ISeo {
  title: string;
  description: string;
  image: string;
  customOGImage?: string;
  customTitle?: string;
  customDescription?: string;
  type?: string;
}

export const Seo = ({
  title,
  description,
  image,
  customOGImage,
  customTitle,
  customDescription,
  type,
}: ISeo) => {
  const router = useRouter();
  const URL = `${process.env.URL}${router.asPath === `/` ? `` : router.asPath}`;
  return (
    <NextSeo
      title={customTitle || title}
      description={customDescription || description}
      openGraph={{
        url: URL,
        title: customTitle || title,
        description: customDescription || description,
        images: [{ url: `${customOGImage || image}` }],
        site_name: `JobsHash`,
        type: type || `website`,
      }}
      twitter={{
        cardType: `summary_large_image`,
      }}
    />
  );
};
